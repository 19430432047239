<template>
  
  <div class="mt-2">
 <div class="card mb-3" style="width: 100%">
     <div class="signature-preview p-4" v-if="showPreview">
        <el-button type="danger" class="p-2" plain @click="showPreview = false;activeName='drawer'"
          ><i class="el-icon-edit"></i> Edit</el-button
        >
        <div class="el-upload">
          <img :src="this.field.source" class="img-fluid"  alt="signature"/>
        </div>
      </div>
  <div class="signature-box" v-loading="verificationLoading" v-else>

   <el-tabs v-model="activeName" class="mt-2">
  <el-tab-pane label="Draw Signature" name="drawer">
    <VueSignaturePad
       style="border: 2px dashed #d9d9d9;margin-top:10px;margin-bottom:10px"
      height="300px"
      ref="signaturePad"
      :options="{
            penColor:selectedSignatureItem.color,
            onBegin: () => {
          $refs.signaturePad.resizeCanvas();
        },
          }"
    />
    <el-button @click="onEnd" type="primary">End Signature</el-button>
    <el-row type="flex" align="middle">
     <el-col :span="8">
    <el-button icon="el-icon-refresh-left" title="Undo" @click="undo"></el-button>
    <button type="button" class="color-btn red" @click="selectedSignatureItem.color = 'red'"></button>
    <button type="button" class="color-btn black" @click="selectedSignatureItem.color = 'black'"></button>
    <button type="button" class="color-btn green" @click="selectedSignatureItem.color = 'green'"></button>
     </el-col>

  </el-row>
  
    </el-tab-pane>
<!-- 
          <el-tab-pane label="Text Signature" name="letter">
            <h4 class="font-weight-normal mb-1" v-if="getCurrentUserSign">Current Signature</h4>
            <div class="el-upload">
              <img :src="getCurrentUserSign" class="img-fluid" />
            </div>
            <el-form>
              <el-form-item class="mb-05" label="Select Style">
                <el-select
                  v-model="value"
                  placeholder="Select"
                  @change="onChangeStyle"
                >
                  <el-option
                    v-for="item in outlet"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option
                ></el-select>
              </el-form-item>
              <el-form-item label="Signature Text">
                <el-input
                  type="text"
                  v-model="signatureText"
                  label="Signature"
                  placeholder="Enter signature"
                ></el-input>

                <Div
                  class="fontsize"
                  :style="{ 'font-family': tstyle, color: this.signatureColor }"
                >
                  {{ this.signatureText }}
                </Div>
              </el-form-item>
            </el-form>
            <div class="my-3">
              <button
                type="button"
                class="color-btn red"
                @click="signatureColor = '#FF0000'"
              ></button>
              <button
                type="button"
                class="color-btn black"
                @click="signatureColor = '#000000'"
              ></button>
              <button
                type="button"
                class="color-btn green"
                @click="signatureColor = '#008000'"
              ></button>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Upload Signature" name="upload">
            <el-row>
              <el-col :span="24">
                <img
                  :src="signatureImage"
                  v-if="signatureImage"
                  class="img-fluid"
                />
                <div v-else>
                  <h4 class="font-weight-normal mb-1" v-if="getCurrentUserSign">Current Signature</h4>
                  <div class="el-upload">
                    <img :src="getCurrentUserSign" class="img-fluid" />
                  </div>
                </div>
                <h4 class="font-weight-normal mb-1">
                  Update New Signature Here
                </h4>
                <el-upload
                  class="avatar-uploader"
                  action
                  :on-change="storeFileToUpload"
                  :show-file-list="false"
                  :auto-upload="false"
                  accept="image/*"
                >
                  <div class="icon-block">
                    <div class="icon">
                      <img
                        src="@/assets/img/icons/upload-icon.svg"
                        alt="icon"
                      />
                    </div>
                    <div class="icon-text">
                      <p>Upload your signature here</p>
                    </div>
                  </div>
                </el-upload>
              </el-col>
            </el-row>
          </el-tab-pane>

 -->

       </el-tabs>

  </div>
 </div>

  </div>
    
</template>
 

<script>
import { bus } from "../../../main";
export default {
  name:"templates-formComponents-SignaturePad",
  props: ["field"],
  data() {
    return {
       activeName: "drawer",
       showPreview: false,
        verificationLoading: false,
      selectedSignatureItem: {
        color: "black"
      }
    };
  },

  async mounted() {
   
    bus.$emit('buttonClickedonload', true);
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.field.source = data;
        bus.$emit('buttonClicked', true);
        // this.form=data;
        this.showPreview = true;
        
      }
    }
  }
};
</script>

<style scoped>

.color-btn {
margin-left: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, .2);
}

.green {
  background: green;
}

.black {
  background: black;
}

.red {
  background: red;
}

.avatar-uploader {
  height: inherit !important;
  justify-content: left !important;
}

.sign_img {
  height: inherit !important;
}

</style>
<style lang="scss" scoped>

.img-fluid {
	max-width: 100%;
	height: auto;
}

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: .0625rem solid rgba(231, 234, 243, .7);
	border-radius: .75rem;
	box-shadow: 0 6px 12px rgba(140, 152, 164, .075);
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.mt-2 {
	margin-top: 2rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.signature-box {
  display: block;

  padding: 0 1em;
  margin: 0 0 5px 0;
  .sign-image {
    // border: 1px solid #dddddd;

    border-radius: 4px;
    margin-bottom: 3px;
    background-color: rgba(0, 0, 0, 0.025);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 80px;
      height: 1px;
      background-color: #999999;
      z-index: 0;
    }
  }

}

.p-2 {
	padding: .5rem !important;
}
.el-col-8 {
  @media (max-width:500px){
    width:100% !important;
  }
}
</style>